var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-container",attrs:{"id":"newsletter"}},[_c('div',{staticClass:"container d-flex flex-column justify-center rounded"},[_c('div',{staticClass:"text-center text-white"},[_c('div',{staticClass:"mb-4"},[_c('h2',[_vm._v(_vm._s(_vm.$t("Newsletter.SignUp")))]),_c('h6',{domProps:{"innerHTML":_vm._s(_vm.$t("Newsletter.EmailOrPhone"))}})]),_c('form',{staticClass:"container px-4 d-md-none d-flex flex-column flex-md-row gap-2 mx-auto",on:{"submit":function($event){$event.preventDefault();return _vm.handleForm()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control p-4 rounded-pill",attrs:{"autocomplete":"off","type":"email","placeholder":_vm.$t('Newsletter.EmailPlaceholder'),"state":_vm.form.email ? _vm.emailFormat.test(_vm.form.email) : null,"required":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('VuePhoneNumberInput',{staticClass:"p-1 form-control",attrs:{"id":"userMobile","placeholder":_vm.$t('Newsletter.PhonePlaceholder'),"default-country-code":_vm.$store.getters.country && _vm.$store.getters.country.Code
                      ? _vm.$store.getters.country.Code
                      : 'CA',"translations":{
                    countrySelectorLabel: _vm.$t('Contact.countrySelectorLabel'),
                    countrySelectorError: _vm.$t('Contact.countrySelectorError'),
                    phoneNumberLabel: _vm.$t('Contact.phoneNumberLabel'),
                    example: 'Ex.',
                  }},on:{"update":function($event){return _vm.setMobilePayload($event)}},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('button',{staticClass:"btn btn-warning rounded-pill py-3 w-100",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Newsletter.Subscribe'))+" ")])],1),_c('b-form',{staticClass:"d-none d-md-block d-md-flex flex-column flex-md-row rounded-pill gap-2 w-75 mx-auto",on:{"submit":function($event){$event.preventDefault();return _vm.handleForm()}}},[_c('div',{staticClass:"input-group p-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control p-4 rounded-pill-left",attrs:{"autocomplete":"off","type":"email","placeholder":_vm.$t('Newsletter.EmailPlaceholder')},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('VuePhoneNumberInput',{staticClass:"p-0 form-control rounded-0",attrs:{"id":"userMobile","placeholder":_vm.$t('Newsletter.PhonePlaceholder'),"default-country-code":_vm.$store.getters.country && _vm.$store.getters.country.Code
                      ? _vm.$store.getters.country.Code
                      : 'CA',"translations":{
                    countrySelectorLabel: _vm.$t('Contact.countrySelectorLabel'),
                    countrySelectorError: _vm.$t('Contact.countrySelectorError'),
                    phoneNumberLabel: _vm.$t('Contact.phoneNumberLabel'),
                    example: 'Ex.',
                  }},on:{"update":function($event){_vm.setMobilePayload($event), _vm.errorMessage = null}},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('button',{staticClass:"btn btn-warning rounded-pill-right py-3 mx-auto",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Newsletter.Subscribe'))+" ")])],1)]),(_vm.errorMessage)?_c('div',{staticClass:"text-warning mt-3"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)]),_c('b-modal',{attrs:{"hide-header":"","hide-footer":"","no-close-on-backdrop":"","no-close-on-esc":"","centered":"","id":"newsletterModal"},scopedSlots:_vm._u([{key:"default",fn:function({ hide }){return [_c('div',[_c('b-button',{staticClass:"close px-2 pb-2",on:{"click":function($event){return hide()}}},[_vm._v("x")])],1),_c('div',{staticClass:"text-center m-3"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Contact.MessageSuccess")))]),_c('div',{staticClass:"mt-4"},[_c('b-link',{on:{"click":function($event){return _vm.$router.go(0)}}},[_vm._v(" "+_vm._s(_vm.$t("Contact.BackLinkText"))+" ")])],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }