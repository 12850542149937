<template>
  <div id="newsletter" class="section-container">
    <div class="container d-flex flex-column justify-center rounded">
        <div class="text-center text-white">
            <div class="mb-4">
                <h2>{{ $t("Newsletter.SignUp") }}</h2>
                <h6 v-html='$t("Newsletter.EmailOrPhone")'></h6>
            </div>
            <!-- mobile form -->
            <form class="container px-4 d-md-none d-flex flex-column flex-md-row gap-2 mx-auto" @submit.prevent="handleForm()">
              <input
                      v-model="form.email"
                      autocomplete="off"
                      type="email"
                      class="form-control p-4 rounded-pill"
                      :placeholder="$t('Newsletter.EmailPlaceholder')"
                      :state="form.email ? emailFormat.test(form.email) : null"
                      required
                  />
                <VuePhoneNumberInput
                id="userMobile"
                class="p-1 form-control"
                v-model="form.phone"
                    :placeholder="$t('Newsletter.PhonePlaceholder')"
                    :default-country-code="
                      $store.getters.country && $store.getters.country.Code
                        ? $store.getters.country.Code
                        : 'CA'
                    "
                    @update="setMobilePayload($event)"
                    :translations="{
                      countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                      countrySelectorError: $t('Contact.countrySelectorError'),
                      phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                      example: 'Ex.',
                    }"
                  />
                  <button
                    type="submit"
                    class="btn btn-warning rounded-pill py-3 w-100"
                    >
                    {{ $t('Newsletter.Subscribe') }}
                </button>
            </form>
            <!-- tablet/desktop form -->
            <b-form class="d-none d-md-block d-md-flex flex-column flex-md-row rounded-pill gap-2 w-75 mx-auto" @submit.prevent="handleForm()">
              <div class="input-group p-0">
                <input
                      v-model="form.email"
                      autocomplete="off"
                      type="email"
                      class="form-control p-4 rounded-pill-left"
                      :placeholder="$t('Newsletter.EmailPlaceholder')"
                  />
                <VuePhoneNumberInput
                id="userMobile"
                class="p-0 form-control rounded-0"
                v-model="form.phone"
                    :placeholder="$t('Newsletter.PhonePlaceholder')"
                    :default-country-code="
                      $store.getters.country && $store.getters.country.Code
                        ? $store.getters.country.Code
                        : 'CA'
                    "
                    @update="setMobilePayload($event), errorMessage = null"
                    :translations="{
                      countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                      countrySelectorError: $t('Contact.countrySelectorError'),
                      phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                      example: 'Ex.',
                    }"
                  />
                  <button
                    type="submit"
                    class="btn btn-warning rounded-pill-right py-3 mx-auto"
                    >
                    {{ $t('Newsletter.Subscribe') }}
                </button>
              </div>
            </b-form>
            <div class="text-warning mt-3" v-if="errorMessage">
              {{ errorMessage }}
            </div>
        </div>
    </div>
      <b-modal
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        centered
        
        id="newsletterModal"
      >
        <template #default="{ hide }">
          <div>
            <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
          </div>
          <div class="text-center m-3">
            <h3>{{ $t("Contact.MessageSuccess") }}</h3>

            <div class="mt-4">
              <b-link @click="$router.go(0)">
                {{ $t("Contact.BackLinkText") }}
              </b-link>
            </div>
          </div>
        </template>
      </b-modal>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import phoneMixin from "@/components/mixins/phoneMixin"
import axios from 'axios'
// import emailPhone from "@/components/mixins/emailPhone";

export default {
    name: "Newsletter",
    components: {
        VuePhoneNumberInput
    },
    mixins: [phoneMixin],
    data() {
        return {
            form: {},
            emailFormat: /\S+@\S+\.\S+/,
            modalContent: null,
            errorMessage: null
        }
    },
    methods: {
      async handleForm() {
        try {
          if (this.form.email || this.form.phone) {
            let payload = {
              CountryCode: $store.getters.country.Code,
              Subject: "Newsletter"
            }
            if (this.form.email) {
              if (!this.emailFormat.test(this.form.email)) {
                return this.errorMessage = this.$t("Validations.email.pleaseEnter");
              }
                payload.Email = this.form.email
            }
            if (this.form.phone) {
              if (!this.validMobile) {
                return this.errorMessage = this.$t("Validations.mobile.validNumber");
              }
              payload.Mobile = this.mobilePayload.formattedNumber
            }
            this.errorMessage = null;
            let response = await axios.post("basic/SubscribeNewsletter", payload)
            console.log(response)
            this.$bvModal.show("newsletterModal");
            window.scrollTo(0,0)
          }
      } catch (err) {
        console.log(err)
        this.errorMessage = "An error has occured, please try again later"
      }
    }
    }
}
</script>

<style lang="scss" scoped>
#newsletter {
  background-color:$light-blue;
}
h2 {
  @media screen and (min-width:992px) {
    font-size: 24px!important;
  }
  @media screen and (min-width:1200px) {
    font-size: 34px!important;
  }
}
#userMobile {
  height:100%!important;
}

form {
  /* max-width:762px; */
}
</style>