import parsePhoneNumber from "libphonenumber-js";

export default {
    computed: {
        validMobile() {
            if (this.mobilePayload) {
              if (this.mobilePayload.isValid) {
                return true;
              }
            }
            return false;
          }
    },
    data() {
        return {
            mobilePayload: null,
        }
    },
    methods: {
        setMobilePayload(event) {
            if(!event.phoneNumber) return false
            const mobileNumber = parsePhoneNumber(event.phoneNumber.toString(), event.countryCode);
            if(mobileNumber) {
              const payload = {
                ...mobileNumber,
                isValid: mobileNumber ? mobileNumber.isValid() : false,
                countryCallingCode: mobileNumber.countryCallingCode,
                formatInternational: mobileNumber.formatInternational(),
                formatNational: mobileNumber.number,
                formattedNumber: mobileNumber.number,
                uri: mobileNumber.getURI(),
                e164: mobileNumber.ext
              }
              this.mobilePayload = payload;
            }
          },
    }
}