<template>
  <div>
    <TheHomePage3 />
    <Newsletter />
  </div>
</template>
<script>
import Newsletter from "@/components/global/Newsletter";
export default {
  name: "Home",
  components: {
    // TheHeader,
    // TheBody,
    Newsletter,
    TheHomePage3: () => import("@/components/home/TheHomePage3.vue"),
  },
};
</script>
